<template>
    <el-aside style="background-color: rgb(238, 241, 246)">
        <el-menu
            active-text-color="#ffd04b"
            background-color="#545c64"
            :default-active="currentPath"
            text-color="#fff"
            :default-openeds="defaultOpened"
            router
        >
            <el-menu-item index="/">
                <i class="el-icon-data-analysis"></i>
                <span>Dashboard</span>
            </el-menu-item>

            <el-menu-item index="/support">
                <i class="el-icon-headset"></i>
                    <span>Online Support</span>
                <el-badge :value="200" :max="99" class="item" style="left: 10px;"></el-badge>
            </el-menu-item>

            <el-sub-menu index="/settings">
                <template #title
                         ><i class="el-icon-setting"></i>Settings</template
                     >
                     <el-menu-item-group>
                         <template #title>Manage Account</template>
                         <el-menu-item index="/settings/userInfo">User Info</el-menu-item>
                         <el-menu-item index="/settings/changePassword">Change Password</el-menu-item>
                     </el-menu-item-group>
                     <el-menu-item-group title="Manage Access" v-if="role != 'Support'">
                         <el-menu-item index="/settings/activation">Activation</el-menu-item>
                     </el-menu-item-group>
            </el-sub-menu>
        </el-menu>
    </el-aside>

</template>

<script>
import { ref, getCurrentInstance, defineComponent } from "vue"

export default defineComponent({
    name : "SideBar",
    components : {},
    data(){
        return { activeIndex : '/' }
    },
    setup() {
        // @ts-ignore
        const { proxy } = getCurrentInstance()
        const currentPath = proxy.$router.currentRoute._value.fullPath
        const defaultOpened = proxy.$router.currentRoute._value.fullPath.startsWith( '/settings' ) ? [ '/settings' ] : [];
        const role = proxy.$store.getters.user.role

        return { proxy, currentPath, defaultOpened, role }
    }
})

</script>

<style>
.el-aside {
    color: var(--el-text-color-primary);
    width: 200px;
    height: 100%;
}

.el-aside ul{
    height: 100%;
}

</style>
