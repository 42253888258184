import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, {
    id: "aside-container",
    style: {"border":"1px solid #eee"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SideBar),
      _createVNode(_component_el_container, { class: "is-vertical" }, {
        default: _withCtx(() => [
          _createVNode(_component_Header),
          _createVNode(_component_el_main, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}