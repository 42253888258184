
import { ref, getCurrentInstance, defineComponent } from "vue"
import SideBar from "@components/SideBar.vue"
import Header from "@components/Header.vue"

export default defineComponent({
    name : "Inactive",
    components : { SideBar, Header },
    setup() {
        // @ts-ignore
        const { proxy } = getCurrentInstance()
    },
})
