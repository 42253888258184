<template>
	<Layout>
		<template #content>
			<div class="main">
				<!-- 上面 -->
				<div class="mainTop">
					<div class="topLeft">
						<div style="display: flex;flex-direction: column;align-items: center;">
							<div class="head"></div>
							<div class="username">用户001</div>
							<div class="username">0098884554</div>
							<div class="company">重庆远见信息产业集团股份有限公司</div>
						</div>

						<div class="upper">
							<div class="xtitle">上月星级</div>
							<div class="stars">
								<img src="../../assets/amaze/image/xingxing.png">
								<img src="../../assets/amaze/image/xingxing.png">
								<img src="../../assets/amaze/image/xingxing.png">
							</div>
						</div>
						<div class="lower">
							<div class="xtitle">下月星级</div>
							<div class="stars">
								<img src="../../assets/amaze/image/xingxing.png">
								<img src="../../assets/amaze/image/xingxing.png">
							</div>
						</div>

						<div style="display: flex;flex-direction: column;align-items: center;">
							<div class="btn">
								<div class="bi">
									<img src="../../assets/amaze/image/bi.png">
								</div>
								<div class="btntext">
									填写下月目标
								</div>
							</div>
						</div>

					</div>
					<div class="topCenter">
						<div class="topCenterBox">
							<div class="topBox">
								<div class="boxS">
									目标处理数
								</div>
								<div class="boxX">
									999
								</div>
							</div>
							<div class="topBox">
								<div class="boxS">
									处理问题数
								</div>
								<div class="boxX" style="color: #0FC28D;">
									1023
								</div>
							</div>
							<div class="topBox" style="position: relative;background: rgba(85, 255, 255, 0.2);">
								<div class="boxS">
									目标处理数
								</div>
								<div class="boxX" style="color: #0FC28D;">
									+23
								</div>
								<img class="up" src="../../assets/amaze/image/shang.png">
							</div>
						</div>
						<div class="topCenterBox">
							<div class="topBox">
								<div class="boxS">
									远程协同数
								</div>
								<div class="boxX">
									999
								</div>
							</div>
							<div class="topBox">
								<div class="boxS">
									远程问题数
								</div>
								<div class="boxX" style="color: #0FC28D;">
									1023
								</div>
							</div>
							<div class="topBox" style="position: relative;background: rgba(85, 255, 255, 0.2);">
								<div class="boxS">
									比上个月
								</div>
								<div class="boxX" style="color: #0FC28D;">
									+12
								</div>
								<img class="up" src="../../assets/amaze/image/shang.png">
							</div>
						</div>
						<div class="topCenterBox">
							<div class="topBox">
								<div class="boxS">
									远程未处理数
								</div>
								<div class="boxX">
									999
								</div>
							</div>
							<div class="topBox">
								<div class="boxS">
									超时处理
								</div>
								<div class="boxX" style="color: #FF7348;">
									1023
								</div>
							</div>
							<div class="topBox" style="position: relative;background: rgba(255, 85, 0, 0.2);">
								<div class="boxS">
									比上个月
								</div>
								<div class="boxX" style="color: #FF7348;">
									+3
								</div>
								<img class="up" src="../../assets/amaze/image/hs.png">
							</div>
						</div>
						<div class="topCenterBox">
							<div class="topBox">
								<div class="boxS">
									远程及时率
								</div>
								<div class="boxX">
									999
								</div>
							</div>
							<div class="topBox">
								<div class="boxS">
									处理及时率
								</div>
								<div class="boxX" style="color: #FF7348;">
									1023
								</div>
							</div>
							<div class="topBox" style="position: relative;background: rgba(255, 85, 0, 0.2);">
								<div class="boxS">
									目标处理数
								</div>
								<div class="boxX" style="color: #FF7348;">
									+3
								</div>
								<img class="up" src="../../assets/amaze/image/hs.png">
							</div>
						</div>
					</div>
					<div class="topRight">
						<div class="topCenterBox">
							<div class="topBox">
								<div class="boxS">
									目标处理数
								</div>
								<div class="boxX">
									999
								</div>
							</div>
							<div class="topBox">
								<div class="boxS">
									处理问题数
								</div>
								<div class="boxX" style="color: #0FC28D;">
									1023
								</div>
							</div>
							<div class="topBox" style="position: relative;background: rgba(85, 255, 255, 0.2);">
								<div class="boxS">
									目标处理数
								</div>
								<div class="boxX" style="color: #0FC28D;">
									+23
								</div>
								<img class="up" src="../../assets/amaze/image/shang.png">
							</div>
						</div>
						<div class="topCenterBox">
							<div class="topBox">
								<div class="boxS">
									远程协同数
								</div>
								<div class="boxX">
									999
								</div>
							</div>
							<div class="topBox">
								<div class="boxS">
									远程问题数
								</div>
								<div class="boxX" style="color: #0FC28D;">
									1023
								</div>
							</div>
							<div class="topBox" style="position: relative;background: rgba(85, 255, 255, 0.2);">
								<div class="boxS">
									比上个月
								</div>
								<div class="boxX" style="color: #0FC28D;">
									+12
								</div>
								<img class="up" src="../../assets/amaze/image/shang.png">
							</div>
						</div>
						<div class="topCenterBox">
							<div class="topBox">
								<div class="boxS">
									远程未处理数
								</div>
								<div class="boxX">
									999
								</div>
							</div>
							<div class="topBox">
								<div class="boxS">
									超时处理
								</div>
								<div class="boxX" style="color: #FF7348;">
									1023
								</div>
							</div>
							<div class="topBox" style="position: relative;background: rgba(255, 85, 0, 0.2);">
								<div class="boxS">
									比上个月
								</div>
								<div class="boxX" style="color: #FF7348;">
									+3
								</div>
								<img class="up" src="../../assets/amaze/image/hs.png">
							</div>
						</div>
						<div class="topCenterBox">
							<div class="topBox">
								<div class="boxS">
									远程及时率
								</div>
								<div class="boxX">
									999
								</div>
							</div>
							<div class="topBox">
								<div class="boxS">
									处理及时率
								</div>
								<div class="boxX" style="color: #FF7348;">
									1023
								</div>
							</div>
							<div class="topBox" style="position: relative;background: rgba(255, 85, 0, 0.2);">
								<div class="boxS">
									目标处理数
								</div>
								<div class="boxX" style="color: #FF7348;">
									+3
								</div>
								<img class="up" src="../../assets/amaze/image/hs.png">
							</div>
						</div>

					</div>
				</div>

				<!-- 下面 -->
				<div class="mainBot">
					<div class="botLeft">
						<div class="botLeftHeader">
							<div class="stick"></div>
							<div class="leftTitle">
								满意度
							</div>
						</div>
						<div id="myChart" style="width: 100%;height: 90%;">

						</div>
					</div>
					<div class="botCenter">
						<div class="botCenterHeader">
							<div class="botLeftHeader" style="margin: 0;">
								<div class="stick"></div>
								<div class="leftTitle">
									处理时长分析
								</div>
							</div>
							<div class="botCenterRight">
								<span style="color: #333333;">平均时长:</span>
								<span style="color: #3B72FF;">2分46秒</span>
							</div>
						</div>
						<!-- 柱状图 -->
						<div id="columnar" style="width: 100%;height: 90%;">

						</div>
					</div>
					<div class="botRight">
						<div class="botRightTop">
							<div class="botRightTopBox" style="background: #3B72FF;">
								<div class="botRightTopBoxImg">
									<img src="../../assets/amaze/image/yingyong.png">
								</div>
								<div class="botRightTopBoxTitle">
									<div>211</div>
									<div>总问题数量</div>
								</div>
							</div>
							<div class="botRightTopBox" style="background: #FF7348;">
								<div class="botRightTopBoxImg">
									<img src="../../assets/amaze/image/bjwj.png">
								</div>
								<div class="botRightTopBoxTitle">
									<div>21</div>
									<div>远程未处理数量</div>
								</div>
							</div>
						</div>
						<div class="botRightCenter">
							<div class="botRightCenterLeft">
								<div>未解决率</div>
								<div>未解决数量</div>
							</div>
							<div class="botRightCenterCenter">
								<span>21</span>
								<span>/300</span>
							</div>
							<div class="botRightCenterRight" id="cake" style="width: 30%;height: 100%;">

							</div>
						</div>
						<div class="botRightBot">
							<div class="botRightCenterLeft">
								<div>已解决率</div>
								<div>已解决数量</div>
							</div>
							<div class="botRightCenterCenter">
								<span style="color: #0FC28D;">279</span>
								<span>/300</span>
							</div>
							<div class="botRightCenterRight" id="cakeTwo" style="width: 30%;height: 100%;">

							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</Layout>
</template>
<script>
	import Layout from "@components/Layout.vue"
	//import {index} from "../../assets/amaze/echarts/index.js"
	//import {echarts} from "../../assets/amaze/echarts/echarts.js"
	//import {myChart} from "../../assets/amaze/echarts/myChart.js"
	const echarts = require('echarts');
	// require('echarts-gl');
	export default {
		name: "Login",
		components: {
			Layout
		},
		data() {
			return {

			}
		},
		mounted() {
			this.chartDom()
			this.chartCol()
			this.chartCake()
			this.chartCakeTwo()
		},
		methods: {
			// 饼图
			chartDom() {
				let myChart = echarts.init(document.getElementById('myChart'))
				var option = {
					tooltip: {
						trigger: 'item',
						// formatter: "{a} <br/>{b} : {c} ({d}%)"
					},
					legend: {
						bottom: '5%',
						orient: 'horizontal',
						formatter:function(name){
							let data = option.series[0].data
							let total = 0
							let tarValue = 0
							for(let i = 0,l = data.length;i<l;i++){
								total += data[i].value
								if(data[i].name == name){
									tarValue = data[i].value
								}
							}
							let p = (tarValue / total *100).toFixed(2)
							return name + ' ' + ' ' + p + '%'
						},
						data:['非常满意','满意','一般','较差','不满意']
					},
					series: [{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						radius: ["60%", "0%"],
						center: ['50%', '40%'],
						data: [{
								value: 1048,
								name: '非常满意'
							},
							{
								value: 735,
								name: '满意'
							},
							{
								value: 580,
								name: '一般'
							},
							{
								value: 484,
								name: '较差'
							},
							{
								value: 300,
								name: '不满意'
							}
						],
						itemStyle: {
							normal: {
								borderWidrh:10,
								borderColor:'#ffffff',
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							}
						}
					}]
				}
				myChart.setOption(option);
				window.addEventListener('resize', function() {
					myChart.resize()
				})
			},
			// 柱图
			chartCol() {
				let columnar = echarts.init(document.getElementById('columnar'))
				// import echarts from 'echarts'
				var charts = { // 按顺序排列从大到小
					cityList: ['0-2min', '2-6min', '6-10min', '10-14min', '14min以上'],
					cityData: [36, 86, 54, 82, 326]
				}
				var top10CityList = charts.cityList
				var top10CityData = charts.cityData
				var color = ['rgba(248,195,248', 'rgba(100,255,249', 'rgba(135,183,255', 'rgba(248,195,248',
					'rgba(100,255,249'
				]

				let lineY = []
				for (var i = 0; i < charts.cityList.length; i++) {
					var x = i
					if (x > color.length - 1) {
						x = color.length - 1
					}
					var data = {
						name: charts.cityList[i],
						color: color[x] + ')',
						value: top10CityData[i],
						itemStyle: {
							normal: {
								show: true,
								color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: color[x] + ', 0.3)'
								}, {
									offset: 1,
									color: color[x] + ', 1)'
								}], false),
								barBorderRadius: 10
							},
							emphasis: {
								shadowBlur: 15,
								shadowColor: 'rgba(0, 0, 0, 0.1)'
							}
						}
					}
					lineY.push(data)
				}

				var option = {
					title: {
						show: false
					},
					tooltip: {
						trigger: 'item'
					},
					grid: {
						borderWidth: 0,
						top: '10%',
						left: '5%',
						right: '15%',
						bottom: '3%'
					},
					color: color,
					yAxis: [{
						type: 'category',
						inverse: true,
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisLabel: {
							show: false,
							inside: false
						},
						data: top10CityList
					}, {
						type: 'category',
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: true,
							inside: false,
							textStyle: {
								color: '#333333',
								fontSize: '14',
								fontFamily: 'PingFangSC-Regular'
							},
							formatter: function(val) {
								return `${val}人`
							}
						},
						splitArea: {
							show: false
						},
						splitLine: {
							show: false
						},
						data: top10CityData
					}],
					xAxis: {
						type: 'value',
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							show: false
						}
					},
					series: [{
						name: '',
						type: 'bar',
						zlevel: 2,
						barWidth: '10px',
						showBackground: true,
						backgroundStyle: {
							color: '#CCCACA',
							borderRadius:30
						},
						data: lineY,
						animationDuration: 1500,
						label: {
							normal: {
								color: '#999999',
								show: true,
								position: [0, '-24px'],
								textStyle: {
									fontSize: 16
								},
								formatter: function(a, b) {
									return a.name
								}
							}
						}
					}, ],
					animationEasing: 'cubicOut'
				}

				columnar.setOption(option);
				window.addEventListener('resize', function() {
					columnar.resize()
				})
			},
			//百分比饼图
			chartCake() {
				let cake = echarts.init(document.getElementById('cake'))
				const handred = 100
				let point = 66

				var option = {
					title: {
						text: point + '%',
						x: '52%',
						y: 'center',
						textStyle: {
							fontWeight: 'normal',
							color: '#333333',
							fontSize: '20'
						}
					},
					tooltip: {
						formatter: function(params) {
							return params.name + '：' + params.percent + ' %'
						}
					},

					series: [{
						name: 'circle',
						type: 'pie',
						clockWise: true,
						radius: ['50%', '66%'],
						center: ['70%', '50%'],
						itemStyle: {
							normal: {
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							}
						},
						hoverAnimation: false,
						data: [{
							value: point,
							name: '占比',
							itemStyle: {
								normal: {
									color: '#FF7348',
									// color: { // 颜色渐变
									// 	colorStops: [{
									// 		offset: 0,
									// 		color: '#4FADFD' // 0% 处的颜色
									// 	}, {
									// 		offset: 1,
									// 		color: '#28E8FA' // 100% 处的颜色1
									// 	}]
									// },
									label: {
										show: false
									},
									labelLine: {
										show: false
									}
								}
							}
						}, {
							name: '剩余',
							value: handred - point,
							itemStyle: {
								normal: {
									color: '#E1E8EE'
								}
							}
						}]
					}]
				}
				cake.setOption(option);
				window.addEventListener('resize', function() {
					cake.resize()
				})
			},
			chartCakeTwo() {
				let cakeTwo = echarts.init(document.getElementById('cakeTwo'))
				const handred = 100
				let point = 66

				var option = {
					title: {
						text: point + '%',
						x: '52%',
						y: 'center',
						textStyle: {
							fontWeight: 'normal',
							color: '#333333',
							fontSize: '20'
						}
					},
					tooltip: {
						formatter: function(params) {
							return params.name + '：' + params.percent + ' %'
						}
					},

					series: [{
						name: 'circle',
						type: 'pie',
						clockWise: true,
						radius: ['50%', '66%'],
						center: ['70%', '50%'],
						itemStyle: {
							normal: {
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							}
						},
						hoverAnimation: false,
						data: [{
							value: point,
							name: '占比',
							itemStyle: {
								normal: {
									color: '#0FC28D',
									// color: { // 颜色渐变
									// 	colorStops: [{
									// 		offset: 0,
									// 		color: '#4FADFD' // 0% 处的颜色
									// 	}, {
									// 		offset: 1,
									// 		color: '#28E8FA' // 100% 处的颜色1
									// 	}]
									// },
									label: {
										show: false
									},
									labelLine: {
										show: false
									}
								}
							}
						}, {
							name: '剩余',
							value: handred - point,
							itemStyle: {
								normal: {
									color: '#E1E8EE'
								}
							}
						}]
					}]
				}
				cakeTwo.setOption(option);
				window.addEventListener('resize', function() {
					cakeTwo.resize()
				})
			}
		}
	}
</script>

<style scoped>
	@import "../../assets/amaze/css/index.css";
</style>
