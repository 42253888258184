<template>
    <el-header style="text-align: right; ">
        <el-dropdown trigger="click">
            <span class="el-dropdown-link" v-if="proxy.$store.getters.isLoggedIn"> {{ proxy.$store.getters.user.username }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="proxy.$router.push( '/settings/userInfo' )" icon="el-icon-user">Profile</el-dropdown-item>
                    <el-dropdown-item @click="logout" icon="el-icon-switch-button" style="border-top: 1px solid #e7ebf4;">Logout</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </el-header>
</template>

<script>
import { ref, getCurrentInstance, defineComponent } from "vue"

export default defineComponent({
    name : "Header",
    components : {},
    setup() {
        // @ts-ignore
        const { proxy } = getCurrentInstance()

        const logout = () => {
            let postParams = { username: proxy.$store.getters.user.username }

            proxy.$post( '/logout', postParams )

            proxy.$store.dispatch( 'logout' )
        }

        return { proxy, logout }
    },
})

</script>

<style scoped>
.el-header {
    background-color: #606266;
    line-height: 60px;
}

.el-dropdown-link {
    cursor: pointer;
    color: #fff;
}

</style>
